import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export default class MaterialService {
  static resource = 'materials';
  static useMock = true;

  private static mockData = {
    materials: [
      {
        id: 1,
        title: 'Student Workbook',
        description: 'Main workbook for students',
        file_name: 'student_workbook.pdf',
        file_url: '/files/student_workbook.pdf',
        record_form_id: 1,
        assessment_id: 1,
        created_at: '2025-01-01T00:00:00.000Z',
      },
      {
        id: 2,
        title: 'Teacher Guide',
        description: 'Comprehensive guide for teachers',
        file_name: 'teacher_guide.pdf',
        file_url: '/files/teacher_guide.pdf',
        record_form_id: 1,
        assessment_id: 1,
        created_at: '2025-01-02T00:00:00.000Z',
      },
    ],
  };

  private static getMockResponse<T>(data: T): AxiosResponse {
    return {
      data,
      status: 200,
      statusText: 'OK',
      headers: { 'Content-Type': 'application/json' },
      config: {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as InternalAxiosRequestConfig,
    };
  }

  static getMaterials(assessmentId: number): ApiPromise {
    if (this.useMock) {
      const materials = this.mockData.materials.filter((material) => material.assessment_id === assessmentId);
      return Promise.resolve(
        this.getMockResponse({
          data: materials,
          meta: { total: materials.length },
        }),
      );
    }
    return HTTP.get(`${this.resource}?assessment_id=${assessmentId}`);
  }

  static createMaterial(payload: {
    title: string;
    description: string;
    file: File;
    record_form_id: number;
    assessment_id: number;
  }): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: {
            id: Math.floor(Math.random() * 1000),
            title: payload.title,
            description: payload.description,
            file_name: payload.file.name,
            file_url: '/files/' + payload.file.name,
            record_form_id: payload.record_form_id,
            assessment_id: payload.assessment_id,
            created_at: new Date().toISOString(),
          },
          meta: { total: this.mockData.materials.length + 1 },
        }),
      );
    }
    return HTTP.post(this.resource, payload);
  }

  static updateMaterial(
    id: number,
    payload: {
      title: string;
      description: string;
      file?: File;
      record_form_id: number;
    },
  ): ApiPromise {
    if (this.useMock) {
      const material = this.mockData.materials.find((m) => m.id === id);
      if (material) {
        material.title = payload.title;
        material.description = payload.description;
        material.record_form_id = payload.record_form_id;
        if (payload.file) {
          material.file_name = payload.file.name;
          material.file_url = '/files/' + payload.file.name;
        }
      }
      return Promise.resolve(
        this.getMockResponse({
          data: material,
          meta: { total: this.mockData.materials.length },
        }),
      );
    }
    return HTTP.put(`${this.resource}/${id}`, payload);
  }

  static deleteMaterial(id: number): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: {},
          meta: { total: this.mockData.materials.length - 1 },
        }),
      );
    }
    return HTTP.delete(`${this.resource}/${id}`);
  }
}
