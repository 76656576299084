import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export default class RecordFormService {
  static resource = 'record-forms';
  static useMock = true;

  private static mockData = {
    recordForms: [
      {
        id: 1,
        title: 'Initial Assessment Form',
        file_name: 'initial_assessment.pdf',
        file_url: '/files/initial_assessment.pdf',
        assessment_id: 1,
        created_at: '2025-01-01T00:00:00.000Z',
      },
      {
        id: 2,
        title: 'Progress Tracking Form',
        file_name: 'progress_tracking.pdf',
        file_url: '/files/progress_tracking.pdf',
        assessment_id: 1,
        created_at: '2025-01-02T00:00:00.000Z',
      },
    ],
  };

  private static getMockResponse<T>(data: T): AxiosResponse {
    return {
      data,
      status: 200,
      statusText: 'OK',
      headers: { 'Content-Type': 'application/json' },
      config: {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as InternalAxiosRequestConfig,
    };
  }

  static getRecordForms(assessmentId: number): ApiPromise {
    if (this.useMock) {
      const forms = this.mockData.recordForms.filter((form) => form.assessment_id === assessmentId);
      return Promise.resolve(
        this.getMockResponse({
          data: forms,
          meta: { total: forms.length },
        }),
      );
    }
    return HTTP.get(`${this.resource}?assessment_id=${assessmentId}`);
  }

  static createRecordForm(payload: { title: string; file: File; assessment_id: number }): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: {
            id: Math.floor(Math.random() * 1000),
            title: payload.title,
            file_name: payload.file.name,
            file_url: '/files/' + payload.file.name,
            assessment_id: payload.assessment_id,
            created_at: new Date().toISOString(),
          },
          meta: { total: this.mockData.recordForms.length + 1 },
        }),
      );
    }
    return HTTP.post(this.resource, payload);
  }

  static updateRecordForm(id: number, payload: { title: string; file?: File }): ApiPromise {
    if (this.useMock) {
      const form = this.mockData.recordForms.find((f) => f.id === id);
      if (form) {
        form.title = payload.title;
        if (payload.file) {
          form.file_name = payload.file.name;
          form.file_url = '/files/' + payload.file.name;
        }
      }
      return Promise.resolve(
        this.getMockResponse({
          data: form,
          meta: { total: this.mockData.recordForms.length },
        }),
      );
    }
    return HTTP.put(`${this.resource}/${id}`, payload);
  }

  static deleteRecordForm(id: number): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: {},
          meta: { total: this.mockData.recordForms.length - 1 },
        }),
      );
    }
    return HTTP.delete(`${this.resource}/${id}`);
  }
}
