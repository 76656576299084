import { defineStore } from 'pinia';
import { AssessmentRecordFormService } from '@/services';

interface RecordFormInterface {
  id: number;
  title: string;
  file_name: string;
  file_url: string;
  assessment_id: number;
  created_at: string;
}

interface RecordFormStoreStateInterface {
  recordForms: {
    loading: boolean;
    data: RecordFormInterface[];
    total: number;
  };
}

export default defineStore('RecordFormStore', {
  state: (): RecordFormStoreStateInterface => ({
    recordForms: {
      loading: false,
      data: [],
      total: 0,
    },
  }),

  actions: {
    async getRecordForms(assessmentId: number) {
      this.recordForms.loading = true;
      try {
        const response = await AssessmentRecordFormService.getRecordForms(assessmentId);
        this.recordForms.data = response.data.data;
        this.recordForms.total = response.data.meta.total;
      } finally {
        this.recordForms.loading = false;
      }
    },

    async createRecordForm(payload: { title: string; file: File; assessment_id: number }) {
      const response = await AssessmentRecordFormService.createRecordForm(payload);
      await this.getRecordForms(payload.assessment_id);
      return response.data;
    },

    async updateRecordForm(id: number, payload: { title: string; file?: File }) {
      const currentForm = this.recordForms.data.find((form) => form.id === id);
      if (!currentForm) return;

      await AssessmentRecordFormService.updateRecordForm(id, payload);
      await this.getRecordForms(currentForm.assessment_id);
    },

    async deleteRecordForm(id: number, assessmentId: number) {
      await AssessmentRecordFormService.deleteRecordForm(id);
      await this.getRecordForms(assessmentId);
    },
  },
});
