export enum TENANT_SETTINGS {
  REFERRALS = 'referrals',
  USES_CONTRACTS = 'uses_contracts',
  OVERLAPPING_EVENTS = 'overlapping_events',
  INTEGRATION_GOOGLE_OAUTH = 'integrations.authentication.google-oauth',
  UTILIZATION_TRACKING = 'use_utilization_tracking',
  CONTRACT_INHERITANCE = 'use_contract_inheritance',
  USES_TENANT_ASSESSMENTS = 'use_tenant_assessments',
}

export enum OAUTH_PROVIDER_SLUG {
  GOOGLE = 'google.oauth',
}
