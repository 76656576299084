import { ApiPromise } from '@/types';
import { AssessmentPayload } from '@/types';
import { HTTP } from '@/utils';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export default class AssessmentService {
  static resource = 'assessments';
  static useMock = true;

  private static mockData = {
    assessments: [
      {
        id: 1,
        title: 'Reading Comprehension Test',
        publisher: 'Publisher One',
        publisher_id: 1,
        created_at: '2025-01-01T00:00:00.000Z',
        language: 'English',
        group: 'Elementary',
        discipline: 'Reading',
        cost: 29.99,
        status: 'active',
        description: 'Comprehensive assessment for reading skills',
      },
      {
        id: 2,
        title: 'Math Skills Evaluation',
        publisher: 'Publisher Two',
        publisher_id: 2,
        created_at: '2025-01-02T00:00:00.000Z',
        language: 'English',
        group: 'Middle School',
        discipline: 'Mathematics',
        cost: 34.99,
        status: 'active',
        description: 'Complete mathematics evaluation tool',
      },
      {
        id: 3,
        title: 'Science Assessment',
        publisher: 'Publisher One',
        publisher_id: 1,
        created_at: '2025-01-03T00:00:00.000Z',
        language: 'Spanish',
        group: 'High School',
        discipline: 'Science',
        cost: 39.99,
        status: 'active',
        description: 'General science knowledge assessment',
      },
    ],
  };

  private static getMockResponse<T>(data: T): AxiosResponse {
    return {
      data,
      status: 200,
      statusText: 'OK',
      headers: {
        'Content-Type': 'application/json',
      },
      config: {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as InternalAxiosRequestConfig,
    };
  }

  static getAssessments(): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: this.mockData.assessments,
          meta: { total: this.mockData.assessments.length },
        }),
      );
    }
    return HTTP.get(this.resource);
  }

  static getAssessment(id: number): ApiPromise {
    if (this.useMock) {
      const assessment = this.mockData.assessments.find((a) => a.id === id);
      return Promise.resolve(
        this.getMockResponse({
          data: assessment,
          meta: {},
        }),
      );
    }
    return HTTP.get(`${this.resource}/${id}`);
  }

  static createAssessment(payload: AssessmentPayload): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: this.mockData.assessments[0],
          meta: { total: this.mockData.assessments.length },
        }),
      );
    }
    return HTTP.post(this.resource, payload);
  }

  static updateAssessment(id: number, payload: AssessmentPayload): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: this.mockData.assessments[0],
          meta: { total: this.mockData.assessments.length },
        }),
      );
    }
    return HTTP.put(`${this.resource}/${id}`, payload);
  }

  static deleteAssessment(id: number): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: {},
          meta: { total: this.mockData.assessments.length - 1 },
        }),
      );
    }
    return HTTP.delete(`${this.resource}/${id}`);
  }
}
