import { defineStore } from 'pinia';
import { AssessmentService } from '@/services';
import { AssessmentInterface, AssessmentPayload } from '@/types';

interface AssessmentStoreStateInterface {
  assessments: {
    loading: boolean;
    data: AssessmentInterface[];
    total: number;
  };
}

export default defineStore('AssessmentStore', {
  state: (): AssessmentStoreStateInterface => ({
    assessments: {
      loading: false,
      data: [],
      total: 0,
    },
  }),

  actions: {
    async getAssessments() {
      this.assessments.loading = true;
      const response = await AssessmentService.getAssessments();
      this.assessments.data = response.data.data;
      this.assessments.total = response.data.meta.total;
      this.assessments.loading = false;
    },

    async getAssessment(id: number) {
      const response = await AssessmentService.getAssessment(id);
      return response.data;
    },

    async createAssessment(payload: AssessmentPayload) {
      await AssessmentService.createAssessment(payload);
      this.getAssessments();
    },

    async updateAssessment(id: number, payload: AssessmentPayload) {
      await AssessmentService.updateAssessment(id, payload);
      this.getAssessments();
    },

    async deleteAssessment(id: number) {
      await AssessmentService.deleteAssessment(id);
      this.getAssessments();
    },
  },
});
