import { defineStore } from 'pinia';
import { MaterialService } from '@/services';

interface MaterialInterface {
  id: number;
  title: string;
  description: string;
  file_name: string;
  file_url: string;
  record_form_id: number;
  assessment_id: number;
  created_at: string;
}

interface MaterialStoreStateInterface {
  materials: {
    loading: boolean;
    data: MaterialInterface[];
    total: number;
  };
}

export default defineStore('MaterialStore', {
  state: (): MaterialStoreStateInterface => ({
    materials: {
      loading: false,
      data: [],
      total: 0,
    },
  }),

  actions: {
    async getMaterials(assessmentId: number) {
      this.materials.loading = true;
      try {
        const response = await MaterialService.getMaterials(assessmentId);
        this.materials.data = response.data.data;
        this.materials.total = response.data.meta.total;
      } catch (error) {
        console.error('Error loading materials:', error);
      } finally {
        this.materials.loading = false;
      }
    },

    async createMaterial(payload: {
      title: string;
      description: string;
      file: File;
      record_form_id: number;
      assessment_id: number;
    }) {
      const response = await MaterialService.createMaterial(payload);
      await this.getMaterials(payload.assessment_id);
      return response.data;
    },

    async updateMaterial(
      id: number,
      payload: {
        title: string;
        description: string;
        file?: File;
        record_form_id: number;
      },
    ) {
      try {
        const currentMaterial = this.materials.data.find((material) => material.id === id);
        if (!currentMaterial) {
          console.error('Material not found:', id);
          return;
        }

        await MaterialService.updateMaterial(id, payload);
        await this.getMaterials(currentMaterial.assessment_id);
      } catch (error) {
        console.error('Error updating material:', error);
        throw error;
      }
    },

    async deleteMaterial(id: number, assessmentId: number) {
      await MaterialService.deleteMaterial(id);
      await this.getMaterials(assessmentId);
    },
  },
});
